<template>
  <environment-template
    :local-air-quality="localAirQuality"
    :usual-environment="usualEnvironment"
    :field-errors="fieldErrors"
    @field-change="onFieldChange"
  />
</template>

<script>
import EnvironmentTemplate from '@/modules/questionnaire/components/steps/questionnaire/oxidative-stress/environment/EnvironmentTemplate';

import { makeStep } from '@/modules/questionnaire/mixins';
import { fieldBuilder } from '@/modules/questionnaire/mixins/makeStep';

const { requiredField, requiredArrayField } = fieldBuilder;

const FIELDS = [requiredField('localAirQuality'), requiredArrayField('usualEnvironment')];

export default {
  name: 'Environment',
  components: { EnvironmentTemplate },
  mixins: [makeStep(FIELDS)],
  watch: {
    localAirQuality() {
      this.scrollTo('#usual-environment');
    }
  }
};
</script>
